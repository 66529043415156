export default {
  namespaced: true,
  state: {
    labelText: '汉风之翼-国际汉语教师服务平台'
  },
  actions: {

  },
  mutations: {    
    setLabelText(state, text){
      state.labelText = text
    }
  }
}