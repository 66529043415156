
// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export default [
  {
    // 未匹配到页面 --> 社会招聘
    path: '*',
    redirect: '/login',
  },
  // 登录
  {
    path: '/login', name: 'Login', component: _import('login'),
    meta: { title: '汉风之翼-国际汉语教师服务平台', type: 'social', headerDark: true }
  },
  // 注册
  {
    path: '/register', name: 'Register', component: _import('register'),
    meta: { title: '汉风之翼-国际汉语教师服务平台', type: 'social', headerDark: true }
  },
  // 重置密码
  {
    path: '/resetPassword', name: 'ResetPassword', component: _import('resetPassword'),
    meta: { title: '汉风之翼-国际汉语教师服务平台', type: 'social', headerDark: true }
  },
  // 首页
  {
    path: '/index', name: 'Index', component: _import('index'),
    meta: { title: '汉风之翼-国际汉语教师服务平台', type: 'social', headerDark: true }
  },
  // 职位列表
  {
    path: '/job/list', name: 'JobList', component: _import('job/list'),
    meta: { title: '汉风之翼-国际汉语教师服务平台', type: 'social', headerDark: true }
  },
  // 职位详情
  {
    path: '/job/detail', name: 'JobDetail', component: _import('job/detail'),
    meta: { title: '职位详情' }
  },
 
  // 活动列表
  {
    path: '/event/list', name: 'EventList', component: _import('event/list'),
    meta: { title: '汉风之翼-国际汉语教师服务平台', type: 'social', headerDark: true }
  },
  
  // 活动详情
  {
    path: '/event/detail', name: 'EventDetail', component: _import('event/detail'),
    meta: { title: '活动详情' }
  },
  
  // 个人中心
  {
    path: '/mine', name: 'Mine', component: _import('mine'),
    meta: { title: '个人中心' }
  },

  // 我的报名
  {
    path: '/mineApplication', name: 'MineApplication', component: _import('mine/application'),
    meta: { title: '我的报名' }
  },

  // 报名预约
  {
    path: '/mineApplicationReservation', name: 'MineApplicationReservation', component: _import('mine/application/reservation.vue'),
    meta: { title: '报名详情' }
  },

  // 我的活动列表
  {
    path: '/mineEventList', name: 'MineEventList', component: _import('mine/eventList'),
    meta: { title: '我的活动' }
  },

  // 常见问答
  {
    path: '/faqList', name: 'FaqList', component: _import('mine/faqList'),
    meta: { title: '常见问答' }
  },

  // 问卷列表
  {
    path: '/questionnaireList', name: 'QuestionnaireList', component: _import('mine/questionnaireList'),
    meta: { title: '问卷列表' }
  },
   // 问卷详情
   {
    path: '/questionnaireDetail', name: 'QuestionnaireDetail', component: _import('mine/questionnaireDetail'),
    meta: { title: '问卷详情' }
  },

   // 联系人列表
   {
    path: '/userContact', name: 'UserContact', component: _import('mine/userContact/index.vue'),
    meta: { title: '家庭联系人' }
  },

  // 联系人详情
  {
    path: '/userContactDetail', name: 'UserContactDetail', component: _import('mine/userContact/detail.vue'),
    meta: { title: '家庭联系人' }
  },

  // 职位报名
  {
    path: '/resume', name: 'Resume', component: _import('resume'),
    meta: { title: '职位报名' }
  },
  // 活动报名
  {
    path: '/eventSubmit', name: 'EventSubmit', component: _import('resume/eventSubmit'),
    meta: { title: '活动报名' }
  },
  // 2023wclc
  {
    path: '/2023wclc', name: '2023wclc', component: _import('2023wclc/index.vue'),
    meta: { title: '2023wclc' }
  },
  // 支付
  {
    path: '/pay', name: 'Pay', component: _import('resume/pay.vue'),
    meta: { title: '支付' }
  },
  // 支付尾款
  {
    path: '/payfinal', name: 'Payfinal', component: _import('resume/payfinal.vue'),
    meta: { title: '支付' }
  },
  // 支付结果
  {
    path: '/payResult', name: 'PayResult', component: _import('resume/payResult.vue'),
    meta: { title: '支付结果' }
  },

  //信息列表
  {
    path: '/messageList', name: 'MessageList', component: _import('message/index.vue'),
    meta: { title: '信息列表' }
  },

  // 信息详情
  {
    path: '/messageDetail', name: 'MessageDetail', component: _import('message/detail.vue'),
    meta: { title: '信息详情' }
  },
  
]
