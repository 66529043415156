export default {
  namespaced: true,
  state: {
    // 学历要求
    educationTypeList: [
      {
        key: 'JuniorMiddle',
        text: '初中及以下'
      },
      {
        key: 'SecondarySpecialized',
        text: '中专/中技'
      },
      {
        key: 'High',
        text: '高中'
      },
      {
        key: 'JuniorCollege',
        text: '大专'
      },
      {
        key: 'Undergraduate',
        text: '本科'
      },
      {
        key: 'Master',
        text: '硕士'
      },
      {
        key: 'Doctor',
        text: '博士'
      }
    ],
    // 职位类别
    workTypeList: [
      {
        key: 'Technology',
        text: '银行柜员'
      }
    ]
  },
  getters: {
    // 获取学历要求文字
    getEducationText: state => key => {
      const item =  state.educationTypeList.find(item => item.key === key)
      return item ? item.text : ''
    },
    
    // 获取职位类型要求文字
    getWorkTypeText: state => key => {
      const item =  state.workTypeList.find(item => item.key === key)
      return item ? item.text : ''
    },

  }
}
