import {formdataify} from '../service'
export default ({ request }) => ({
 
   /**
   * 获取工作地点列表
   * @param data
   * @returns {*}
   * @constructor
   */
  GET_JOB_ALL() {
    // 接口请求
    return request({
      url: `/api/job/job_all`,
      method: 'GET'
    })
  },

  /**
     * 获取工作地点列表
     * @param data
     * @returns {*}
     * @constructor
     */
   GET_WORKCITY_LIST() {
    // 接口请求
    return request({
      url: `/api/job/city`,
      method: 'GET'
    })
  },
  /**
   * 获取职位类别
   * @param data
   * @returns {*}
   * @constructor
   */
  GET_JOBTYPE_LIST() {
    // 接口请求
    return request({
      url: `/api/job/position`,
      method: 'GET'
    })
  },
  /**
  * 获取职位列表
  * @param data
  * @returns {*}
  * @constructor
  */
  GET_POSITION_LIST(params = {}) {
    // 接口请求
    return request({
      url: '/api/job/job_list',
      method: 'GET',
      params
    })
  },

  
  /**
     * 根据主键获取职位明细
     * @param params
     * @returns {*}
     * @constructor
     */
   GET_POSITION_DETAIL(params) {
    // 接口请求
    return request({
      url: `/api/job/detail`,
      method: 'GET',
      params
    })
  },

  /**
  * 投递职位
  * @param data
  * @returns {*}
  * @constructor
  */
   POSITION_DELIVERY(data = {}) {
    // 接口请求
    return request({
      url: '/position/delivery',
      method: 'POST',
      data
    })
  },

  
  /**
  * 收藏职位
  * @param data
  * @returns {*}
  * @constructor
  */
   POSITION_COLLECT(data = {}) {
    // 接口请求
    return request({
      url: '/position/collection',
      method: 'POST',
      data
    })
  },

  /**
  * 取消收藏
  * @param data
  * @returns {*}
  * @constructor
  */
   POSITION_COLLECT_DELETE(data = {}) {
    // 接口请求
    return request({
      url: '/position/collection/cannel',
      method: 'POST',
      data
    })
  },

  /**
   * 登录
   * @param data
   * @returns {*}
   * @constructor
   */
   SYS_USER_LOGIN(data = {}) {
    // 接口请求
    return request({
      url: `/api/auth/login`,
      method: 'POST',
      data: formdataify(data)
    })
  },

  /**
   * 登录获取验证码
   * @param data
   * @returns {*}
   * @constructor
   */
  SYS_VALID_VODE(data = {}) {
    // 接口请求
    return request({
      url: `/api/auth/sendLoginSms`,
      method: 'POST',
      data:formdataify(data)
    })
  },

  /**
   * 注册
   * @param data
   * @returns {*}
   * @constructor
   */
   SYS_USER_REG(data = {}) {
    // 接口请求
    return request({
      url: `/api/auth/reg`,
      method: 'POST',
      data: formdataify(data)
    })
  },

  /**
   * 注册获取验证码
   * @param data
   * @returns {*}
   * @constructor
   */
  SYS_VALID_REG(data = {}) {
    // 接口请求
    return request({
      url: `/api/auth/sendRegSms`,
      method: 'POST',
      data:formdataify(data)
    })
  },

  /**
   * 获取验证码
   * @param params
   * @returns {*}
   * @constructor
   */
  GET_MYSIGN_LIST(params = {}) {
    // 接口请求
    return request({
      url: `/api/job_sign/getMySignList`,
      method: 'GET',
      params:params
    })
  },

  /**
   * 找回密码
   * @param data
   * @returns {*}
   * @constructor
   */
   SYS_USER_PASS(data = {}) {
    // 接口请求
    return request({
      url: `/api/auth/resset_password`,
      method: 'POST',
      data: formdataify(data)
    })
  },

  /**
   * 找回密码获取验证码
   * @param data
   * @returns {*}
   * @constructor
   */
  SYS_VALID_PASS(data = {}) {
    // 接口请求
    return request({
      url: `/api/auth/sendResSetPasswordSms`,
      method: 'POST',
      data:formdataify(data)
    })
  },
  
})

