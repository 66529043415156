import Cookies from 'js-cookie'

const cookies = {}
const cookiePrefix = `winning-recruit-${process.env.VUE_APP_VERSION}`
/**
 * @description 存储 cookie 值
 * @param {String} name cookie name
 * @param {String} value cookie value
 * @param {Object} setting cookie setting
 */
cookies.set = function(name = 'default', value = '', cookieSetting = {}) {
  const currentCookieSetting = {
    // cookie过期时间设置为7天
    expires: 7
  }
  Object.assign(currentCookieSetting, cookieSetting)
  Cookies.set(`${cookiePrefix}-${name}`, value, currentCookieSetting)
}

/**
 * @description 拿到 cookie 值
 * @param {String} name cookie name
 */
cookies.get = function(name = 'default') {
  return Cookies.get(`${cookiePrefix}-${name}`)
}

/**
 * @description 拿到 cookie 全部的值
 */
cookies.getAll = function() {
  return Cookies.get()
}

/**
 * @description 删除 cookie
 * @param {String} name cookie name
 */
cookies.remove = function(name = 'default') {
  return Cookies.remove(`${cookiePrefix}-${name}`)
}

export default cookies
