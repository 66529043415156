import {formdataify} from '../service'
export default ({ request }) => ({
 
   /**
   * 联系人列表
   * @param data
   * @returns {*}
   * @constructor
   */
  GET_USER_CONTACT_ALL(params) {
    // 接口请求
    return request({
      url: `/api/user_contact/index`,
      method: 'GET',
      params: params
    })
  },

  /**
   * 联系人详情
   * @param data
   * @returns {*}
   * @constructor
   */
  GET_USER_CONTACT_DETAIL(params) {
    // 接口请求
    return request({
      url: `/api/user_contact/detail`,
      method: 'GET',
      params: params
    })
  },

   /**
   * 联系人新增
   * @param data
   * @returns {*}
   * @constructor
   */
   GET_USER_CONTACT_ADD(data) {
    // 接口请求
    return request({
      url: `/api/user_contact/add`,
      method: 'POST',
      data: formdataify(data)
    })
  },

  /**
   * 联系人编辑
   * @param data
   * @returns {*}
   * @constructor
   */
  GET_USER_CONTACT_EDIT(data) {
    // 接口请求
    return request({
      url: `/api/user_contact/edit`,
      method: 'POST',
      data: formdataify(data)
    })
  },

  /**
  * 联系人删除
  * @param data
  * @returns {*}
  * @constructor
  */
  GET_USER_CONTACT_DEL(data) {
    // 接口请求
    return request({
      url: '/api/user_contact/del',
      method: 'POST',
      data: formdataify(data)
    })
  }
  
})

