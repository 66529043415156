import Vue from 'vue'
import App from './App.vue'
import api from '@/api'
import '@/assets/svg-icons'
import IconSvg from '@/components/icon-svg'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import '@/assets/style/vant_pc.scss'
// vant所需组件
import '@/components/vant'
// store
import store from '@/store/index'
// 路由设置
import router from './router'
// filter
import filter from './filters'
// 微信js-sdk
// import wx from "weixin-js-sdk"

import { Input, Select, Option, Loading } from 'element-ui';

Vue.use(Input).use(Option).use(Select).use(Loading)

import VueAppend from 'vue-append'

Vue.config.productionTip = false
Vue.prototype.$api = api

Vue.use(VueVideoPlayer)
Vue.use(VueAppend)
Vue.component('icon-svg', IconSvg)

Object.keys(filter).forEach(k => Vue.filter(k, filter[k]))
new Vue({
  router,
  store,
  render: h => h(App),
  created() { }
}).$mount('#app')