import axios from 'axios'
import { errorCreate } from './tools.js'
import { get, merge } from 'lodash'
import cookies from '@/libs/util.cookies'
import router from '@/router'

/**
 * @description 创建请求实例
 */
function createService () {
  // 创建一个 axios 实例
  const service = axios.create()
  // 请求拦截
  service.interceptors.request.use(
    config => config,
    error => {
      // 发送失败
      return Promise.reject(error)
    }
  )
  // 响应拦截
  service.interceptors.response.use(
    response => {
      // dataAxios 是 axios 返回数据中的 data
      const dataAxios = response.data
      // 这个状态码是和后端约定的
      const { code } = dataAxios
      // 根据 code 进行判断
      if (code === undefined) {
        // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
        return dataAxios
      } else {
        // 有 code 代表这是一个后端接口 可以进行进一步的判断
        switch (code) {
          case 200:
            return dataAxios.data
          case 1101:
            router.push({
              name:'Login'
            })
            return 
          case 1102:
            router.push({
              name:'Login'
            })
            return 
          default:
            // 不是正确的 code
            errorCreate(dataAxios.msg)
            break
        }
      }
    },
    error => {
      // errorCreate(`请求失败`)
      return Promise.reject(error)
    }
  )
  return service
}

/**
 * @description 创建请求方法
 * @param {Object} service axios 实例
 */
function createRequestFunction (service) {
  return function (config) {
    const configDefault = {
      headers: {
        'Content-Type': get(config, 'headers.Content-Type', 'application/json'),
        'token' : cookies.get(process.env.VUE_APP_TOKEN) || ''
      },
      timeout: 50000,
      baseURL: process.env.VUE_APP_API,
      data: {}
    }
    return service(merge(configDefault, config))
  }
}

export const formdataify = (params = {}) => {
  const formData = new FormData();
  Object.keys(params).forEach(key => {
    if (typeof params[key] == "string") {
      formData.append(key, params[key]);
    } else {
      formData.append(key, JSON.stringify(params[key]));
    }
  });
  return formData;
};


// 用于真实网络请求的实例和请求方法
export const service = createService()
export const request = createRequestFunction(service)
