export default {
  namespaced: true,
  state: {
    workList: []
  },
  actions: {
  },
  mutations: {    
    setWorkList(state, work){
      state.workList.push(work)
    }
  }
}