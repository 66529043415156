
export default ({ request }) => ({
 
   /**
   * 站内信列表页
   * @param data
   * @returns {*}
   * @constructor
   */
  GET_MESSAGE_ALL(params) {
    // 接口请求
    return request({
      url: `/api/message/index`,
      method: 'GET',
      params
    })
  },

  /**
     * 站内信详情
     * @param data
     * @returns {*}
     * @constructor
     */
   GET_MESSAGE_DETAIL(params) {
    // 接口请求
    return request({
      url: `api/message/detail`,
      method: 'GET',
      params
    })
  },
  /**
   * 未读数量
   * @param data
   * @returns {*}
   * @constructor
   */
  GET_MESSAGE_USERUNREAD() {
    // 接口请求
    return request({
      url: `api/message/getUserUnreadMessage`,
      method: 'GET'
    })
  }

})

