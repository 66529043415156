<template>
  <div id="app">
    <van-sticky>
      <Header v-if="showHeader" :key="$route.path" :dark="headerDark" :showTextLabel="headerLabelVisible" />
    </van-sticky>
    <router-view class="page_content" />
  </div>
</template>

<script>
import Header from "@/components/Header";
import { mapMutations } from "vuex";
import util from "@/libs/util.js";

export default {
  name: "App",
  components: {
    Header,
  },
  data() {
    return {
      headerDark: true,
      headerLabelVisible: true,
      isWeixin: false,
    };
  },
  computed: {
    showHeader() {
      const name = this.$route.name;
      return name === 'Login'  || name === 'Register' || name==='2023wclc' || name === 'ResetPassword' ? false : true;
    },
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler: function (newPath, oldPath) {
        if (newPath === oldPath || !newPath) {
          return;
        }
        this.headerDark = this.$route.meta.headerDark;
        if (newPath.startsWith("/job")) {
          this.headerLabelVisible = true;
        } else {
          this.headerLabelVisible = false;
        }
     },
    },
  },
  created() {
    util.title("汉风之翼-国际汉语教师服务平台");
  },
  mounted() {},
  methods: {
    ...mapMutations("header", ["setLabelText"]),
  },
};
</script>

<style lang="scss">
/* 项目通用自定义样式 */
@import "~@/assets/style/index.css";

</style>
