<template>
  <div id="header" :class="[dark ? 'dark' : 'white']" :key="navKey">
    <div class="header-nav">
      <img
        v-if="isMobile"
        class="icon-logo"
        @click="logoClick"
        src="@/assets/images/common/logo-m.png"
        alt=""
      />

      <img
        v-else
        class="icon-logo"
        @click="logoClick"
        src="@/assets/images/common/logo.png"
        alt=""
      />
    </div>

    <div class="header-nav">
      <span v-if="isLogin" class="user-name" @click="showPopup()">{{username}} </span>

      <van-badge v-if="isLogin && unread_msg > 0" dot>
        <van-icon class="header-nav-envelop-o" name="envelop-o" @click="goMessageList" />
      </van-badge>

      <van-icon v-if="isLogin && unread_msg == 0" class="header-nav-envelop-o" name="envelop-o"  @click="goMessageList" />

      <router-link v-if="!isMobile && !isLogin" class="menu-item" to="/login"
        >登录</router-link>
      <router-link v-if="!isMobile && !isLogin" class="menu-item" to="/register"
        >注册</router-link>
      <icon-svg
        v-if="isMobile"
        class="icon"
        :name="`bars-dark`"
        @click.native="showPopup()"
      ></icon-svg>

   

    </div>

    <van-popup
      v-model="popupVisible"
      position="top"
      class="popup"
      closeable
      round
      :close-on-click-overlay="true"
      :close-on-popstate="true"
      @click="clickPopup"
    >

  
      <div class="popup-header">
        <img v-if="isMobile" class="icon-logo" src="@/assets/images/common/logo-m.png" alt="" />
      </div>
      <div class="divider" />
      <div class="popup-menu-list">
        <router-link v-if="isMobile && !isLogin" class="menu-item" to="/login"
          >登录</router-link>
        <router-link v-if="isMobile && !isLogin" class="menu-item" to="/register"
          >注册</router-link>
        <router-link v-if="isLogin" class="menu-item" to="/mine"
          >个人中心</router-link>
        <a
          v-if="isLogin"
          class="menu-item"
          href="javascript:;"
          @click="logOut"
          >注销</a>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import cookies from "@/libs/util.cookies";

export default {
  name: "Header",
  props: {
    dark: {
      type: Boolean,
      defalt: false,
    },
    showTextLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      popupVisible: false,
      path: "",
      navKey:Math.random(),
      unread_msg:0
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.path = newValue;
      },
    },
  },
  computed: {
    ...mapState("header", ["labelText"]),
    iconSuffix() {
      return this.dark ? "white" : "dark";
    },
    isMobile() {
      return document.body.clientWidth < 992;
    },
    isLogin() {
      return cookies.get(process.env.VUE_APP_TOKEN);
    },
    username(){
      return cookies.get('username');
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init(){
      this.$api.GET_MESSAGE_USERUNREAD().then(res=>{
        this.unread_msg = res.unread_msg
      })
    },
    showPopup() {
      this.popupVisible = true;
    },
    clickPopup() {
      this.popupVisible = false;
    },
    logoClick() {
      if (this.$route.path === "/index") return;
      this.$router.push({ path: "/index" });
    },
    routerAuth(path) {
      const token = cookies.get(process.env.VUE_APP_TOKEN);
      if (!token) {
        this.$router.push({
          name: "Login",
        });
        return;
      }
      this.$router.push({ path: path });
    },
    logOut(){
      cookies.set(process.env.VUE_APP_TOKEN,'');
      cookies.set('username','');
      this.navKey = Math.random()
      window.location.reload()
    },
    goMessageList(){
      this.$router.push({
        name: "MessageList",
      });
    }
  },
};
</script>

<style lang="scss" scoped>
#header {
  /* width: 100%; */
  display: flex;
  padding: 0 10px 0 0px;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  height: 50px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
  overflow:hidden;
}

#header.white {
  background-color: #fff;
}

.header-nav {
  display: flex;
  align-items: center;
  color: white;
  font-size: 16px;
  .user-name{
    color:#333;
    cursor: pointer;
  }
}

.white .header-nav {
  color: #e95944;
}

.icon-logo {
  width: 50px;
  height: auto;
  margin-left: 5px;
}

.title-label {
  width: 57px;
  height: 21px;
  font-size: 11px;
  color: #ffffff;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: cetner;
}

.title-label-bg {
  width: 100%;
  height: 100%;
}

.title-label-text {
  width: 100%;
  margin-left: -57px;
  margin-top: -2px;
}

.white .header-nav {
  color: #00a1d6;
}

.icon {
  width: 32px;
  height: 32px;
  margin-left: 3px;
}

.popup-header {
  height: 50px;
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: grey;
  opacity: 0.15;
}
.popup-menu-list {
  font-size: 16px;
  padding-bottom: 20px;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 40px;
  color: black;

}

.menu-item.active {
  color: #e95944;
  font-weight: bold;
}

.router-link-active {
  color: #e95944;
  font-weight: bold;
}

.header-nav-envelop-o{
  color:#333;
  margin-left:10px;
  cursor: pointer;
}

::v-deep .van-badge--dot{
  width: 8px;
  height: 8px;
}

::v-deep .van-badge{
  padding:0 3px;
}

@media (min-width: 992px) {

  .header-nav-envelop-o{
    color:#333;
    margin-left:10px;
  }

  ::v-deep .van-badge--dot{
    width: 8px;
    height: 8px;
  }

  ::v-deep .van-badge{
    padding:0 3px;
  }

  #header {
    padding: 0 10px 0 0px;
    height: 68px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
    overflow:hidden;
  }

  .header-nav {
    font-size: 16px;
  }

  .icon-logo {
    width: 60px;
    height: auto;
    margin-left: 5px;
  }

  .title-label {
    width: 57px;
    height: 21px;
    font-size: 11px;
    line-height: 16px;
  }

  .title-label-bg {
    width: 100%;
    height: 100%;
  }

  .title-label-text {
    width: 100%;
    margin-left: -57px;
    margin-top: -2px;
  }


  .icon {
    width: 32px;
    height: 32px;
    margin-left: 3px;
  }

  .popup-header {
    height: 50px;
    padding: 0 15px;
  }

  .divider {
    width: 100%;
    height: 1px;
    opacity: 0.15;
  }
  .popup-menu-list {
    font-size: 16px;
    padding-bottom: 20px;
  }

  .menu-item {
    padding: 0 5px;
    height: 40px;
    justify-content: center;
  }
}
</style>
