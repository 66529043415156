import {formdataify} from '../service'
export default ({ request }) => ({
 
   /**
   * 活动列表
   * @param data
   * @returns {*}
   * @constructor
   */
  GET_EVENT_ALL(params) {
    // 接口请求
    return request({
      url: `/api/event/index`,
      method: 'GET',
      params: params
    })
  },

  /**
   * 我的活动列表
   * @param data
   * @returns {*}
   * @constructor
   */
  GET_MY_EVENT_ALL(params) {
    // 接口请求
    return request({
      url: `/api/event/mySignEvent`,
      method: 'GET',
      params: params
    })
  },


  /**
   * 常见问答
   * @param data
   * @returns {*}
   * @constructor
   */
  GET_FAQ_ALL(params) {
    // 接口请求
    return request({
      url: `/api/faq/index`,
      method: 'GET',
      params: params
    })
  },

   /**
   * 问卷列表
   * @param data
   * @returns {*}
   * @constructor
   */
   GET_QUESTIONNAIRE_ALL(params) {
    // 接口请求
    return request({
      url: `/api/questionnaire/index`,
      method: 'GET',
      params: params
    })
  },

   /**
   * 问卷详情
   * @param data
   * @returns {*}
   * @constructor
   */
   GET_QUESTIONNAIRE_DETAIL(params) {
    // 接口请求
    return request({
      url: `/api/questionnaire/detail`,
      method: 'GET',
      params: params
    })
  },

   /**
   * 提交问卷
   * @param data
   * @returns {*}
   * @constructor
   */
   GET_QUESTIONNAIRE_SIGN(data) {
    // 接口请求
    return request({
      url: `/api/questionnaire/saveSign`,
      method: 'POST',
      data: formdataify(data)
    })
  },

  /**
   * 预约列表
   * @param data
   * @returns {*}
   * @constructor
   */
  GET_JOB_INTERVIEW_LIST(params) {
    // 接口请求
    return request({
      url: `/api/job_interview/view_list`,
      method: 'GET',
      params: params
    })
  },

  /**
   * 提交预约
   * @param data
   * @returns {*}
   * @constructor
   */
  GET_JOB_INTERVIEW_DO(data) {
    // 接口请求
    return request({
      url: `/api/job_interview/doInterview`,
      method: 'POST',
      data: formdataify(data)
    })
  },

  /**
   * 获取预约详情
   * @param data
   * @returns {*}
   * @constructor
   */
  GET_JOB_INTERVIEW_INFO(data) {
    // 接口请求
    return request({
      url: `/api/job_interview/getInterviewInfo`,
      method: 'POST',
      data: formdataify(data)
    })
  },


   /**
   * 活动详情
   * @param data
   * @returns {*}
   * @constructor
   */
   GET_EVENT_DETAIL(params) {
    // 接口请求
    return request({
      url: `/api/event/detail`,
      method: 'GET',
      params: params
    })
  },

  /**
  * 活动报名
  * @param data
  * @returns {*}
  * @constructor
  */
  EVENT_SAVE_SIGN(data) {
    // 接口请求
    return request({
      url: '/api/event/saveSign',
      method: 'POST',
      data: formdataify(data)
    })
  }
  
})

