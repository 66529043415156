
const util = {}

/**
 * @description 更新标题
 * @param {String} title 标题
 */
util.title = function (titleText) {
  window.document.title = titleText
}

/**
 * @description 删除URL内微信信息
 * @param {String} url 标题
 */
util.deleteWechatInfo = function (url) {
  let codeIndex = url.indexOf('code=')
  if (codeIndex === -1) {
    // code不存在直接返回
    return url
  }
  let stateStr = `state=WnHRWxSTATE`
  let eIndex = url.indexOf(stateStr)
  if(eIndex !== -1) {
    // state存在, 删除code到state的字符串
    return url.substr(0, codeIndex) + url.substr(eIndex + stateStr.length)
  } else {
    // state不存在, 删除'code'
    return url.substr(0, codeIndex) + url.substr(codeIndex + 5)
  }
}

export default util
