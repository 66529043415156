export default ({ request }) => ({
  /**
   * 获取职位投递列表
   * @param params
   * @returns {*}
   * @constructor
   */
   POSITION_DELIVERY_LIST (params = {}) {
    // 接口请求
    return request({
      url: '/api/resume/resume_send_list',
      method: 'GET',
      params
    })
  }
})

