export default {
  namespaced: true,
  state: {
    history: []
  },
  actions: {
    pushHistory({ commit }, current) {
      commit("setHistory", current);
    }
  },
  mutations: {    
    setHistory(state,current){
      if(state.history.length > 5){
        state.history.shift()
      }
      state.history.push(current)
    }
  }
}